import React from 'react';
import './AboutPage.scss';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const AboutPage = () => {
    return (
        <div className="about-page">
            <Navbar/>
            <section className="about-header">
                <div className='wrapper'>
                    <div className="outer-frame"></div>
                    <div className="inner-container">
                        <h1>Why us</h1>
                    </div>
                </div>
            </section>

            <section className="why-us-content">
                <div className="why-us-wrapper">
                    {/*<h2>Why Us</h2>*/}
                    <div className="why-us-section">
                        <h3>Client-Centric</h3>
                        <ul>
                            <li><strong>Cost Optimization:</strong> By strategically selecting R&D tools, we have enabled clients to save <strong>20%</strong> on machine learning operational costs compared to their previous expenditures while maintaining quality.</li>
                            <li><strong>Self-Service Strategy:</strong> For needs where mature solutions exist, we encourage clients handle the work themselves using AI tools like ChatGPT. This way, you can avoid the extra costs.</li>
                            <li><strong>Suitability:</strong> In some cases, from a machine learning perspective, the client's products or services are not suitable for machine learning applications. Even if used, the profitability would be limited</li>
                        </ul>
                    </div>
                    <div className="why-us-section">
                        <h3>High-Quality Solutions</h3>
                        <ul>
                            <li><strong>Innovative Methods:</strong> We offer flexible solutions that go beyond end-to-end approaches. By combining engineering expertise with multi-model capabilities, we are able to meet the needs of each client. This may also reduce long-term ML operational costs.</li>
                            <li><strong>System Reliability:</strong> We deliver secure and architecturally sound solutions.  In a project with a public financial organization, we successfully identified the architectural flaws in a machine learning system that a previous contractor had left.</li>
                        </ul>
                    </div>

                </div>
            </section>

            <Footer />
        </div>
    );
};

export default AboutPage;
