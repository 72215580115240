import React from 'react';
import {useState, useRef, useEffect} from 'react';
import {useNavigate, useLocation, Link} from 'react-router-dom';
import './navbar.scss'; // Or './Navbar.css' if you're using CSS
import company from '../../assets/images/company2.png'

function Navbar() {
    const navigate = useNavigate();
    const location = useLocation();

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    const [showServices, setShowServices] = useState(false);
    const [showSolutions, setShowSolutions] = useState(false);
    const [showIndustries, setShowIndustries] = useState(false);
    const [showCaseStudies, setShowCaseStudies] = useState(false);
    const servicesTimer = useRef(null);
    const solutionsTimer = useRef(null);
    const industriesTimer = useRef(null);
    const caseStudiesTimer = useRef(null);

    const handleMouseEnter = (setter) => {
        clearTimeout(servicesTimer.current);
        clearTimeout(solutionsTimer.current);
        clearTimeout(industriesTimer.current);
        clearTimeout(caseStudiesTimer.current);
        setter(true);
    };

    const handleMouseLeave = (setter) => {
        clearTimeout(servicesTimer.current);
        clearTimeout(solutionsTimer.current);
        clearTimeout(industriesTimer.current);
        clearTimeout(caseStudiesTimer.current);
        setter(false); // 直接隐藏下拉菜单
    };

    const handleServiceClick = () => {
        // Check if the current page is the homepage
        if (location.pathname === '/') {
            const section = document.getElementById('our-service-section');
            if (section) {
                section.scrollIntoView({behavior: 'smooth'});
            }
        } else {
            // Redirect to the homepage and then scroll to the 'service-section'
            navigate('/#our-service-section');
        }
    };


    useEffect(() => {
        function handleResize() {
            const currentIsMobile = window.innerWidth <= 768;
            setIsMobile(currentIsMobile);

            // 当从小屏幕变为大屏幕时，确保菜单是展开的
            if (!currentIsMobile && isMobileMenuOpen) {
                setIsMobileMenuOpen(false);
            }

            // 当从大屏幕变为小屏幕时，确保菜单是关上的
            if (currentIsMobile && !isMobileMenuOpen) {
                setIsMobileMenuOpen(true);
            }
        }

        window.addEventListener('resize', handleResize);

        // 清除事件监听器
        return () => window.removeEventListener('resize', handleResize);
    }, [isMobileMenuOpen]);

    useEffect(() => {
        if (isMobile) {
            setIsMobileMenuOpen(true);
        }
    }, [location]); // 依赖于location和isMobile状态

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                {/*<a href="/">Majority AI</a>*/}
                <a href='/'>
                    <img className={"img-zoom"} src={company} alt="Company Logo" style={{ height: '50px' }} />
                </a>
            </div>
            <div className="hamburger" onClick={toggleMobileMenu}>
                &#9776;
            </div>
            <ul className={`navbar-links ${isMobileMenuOpen ? 'show' : ''}`}
                style={{display: isMobileMenuOpen ? 'none' : 'flex'}}>
                {/*<ul className={`navbar-links ${isMobileMenuOpen ? 'show' : ''}`}>*/}

                <li
                    onMouseEnter={() => handleMouseEnter(setShowServices, servicesTimer)}
                    onMouseLeave={() => handleMouseLeave(setShowServices, servicesTimer)}
                >
                    {/*<a href="#" onClick={handleServiceClick}>Services</a>*/}
                    <a href="#">Services</a>
                    {showServices && (
                        <div className={`services-dropdown ${showServices ? 'visible' : 'hidden'}`}>
                            <Link to="/solutions">Solution Development</Link>
                            <Link to="/improvement">Solution Improvement</Link>
                            <Link to="/consultation">AI Strategy and Consultation</Link>
                        </div>
                    )}
                </li>
                {/*这是有用的不能删，这里是navbar顶上面的solutions和industry部分，暂时没做好先隐藏了*/}
                {/*这是有用的不能删，这里是navbar顶上面的solutions和industry部分，暂时没做好先隐藏了*/}
                {/*这是有用的不能删，这里是navbar顶上面的solutions和industry部分，暂时没做好先隐藏了*/}

                {/*<li*/}
                {/*    onMouseEnter={() => handleMouseEnter(setShowSolutions, solutionsTimer)}*/}
                {/*    onMouseLeave={() => handleMouseLeave(setShowSolutions, solutionsTimer)}*/}
                {/*>*/}
                {/*    <a href="#">Solutions</a>*/}
                {/*    {showSolutions && (*/}
                {/*        <div className={`solutions-dropdown ${showSolutions ? 'visible' : 'hidden'}`}>*/}
                {/*            <a href="/virtual-assistant-tool">Virtual Assistant Tool</a>*/}
                {/*            <a href="/custom-large-language-models">Custom Large Language Models</a>*/}
                {/*            <a href="/natural-language-processing">Natural Language Processing</a>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    onMouseEnter={() => handleMouseEnter(setShowIndustries, industriesTimer)}*/}
                {/*    onMouseLeave={() => handleMouseLeave(setShowIndustries, industriesTimer)}*/}
                {/*>*/}
                {/*    <a href="#">Industries</a>*/}
                {/*    {showIndustries && (*/}
                {/*        <div className={`industries-dropdown ${showIndustries ? 'visible' : 'hidden'}`}>*/}
                {/*            <a href="/healthcare">Healthcare</a>*/}
                {/*            <a href="/finance">Finance</a>*/}
                {/*            <a href="/education">Education</a>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</li>*/}

                {/*这是有用的不能删，这里是navbar顶上面的solutions和industry部分，暂时没做好先隐藏了*/}
                {/*这是有用的不能删，这里是navbar顶上面的solutions和industry部分，暂时没做好先隐藏了*/}
                {/*这是有用的不能删，这里是navbar顶上面的solutions和industry部分，暂时没做好先隐藏了*/}


                <li
                    onMouseEnter={() => handleMouseEnter(setShowCaseStudies, caseStudiesTimer)}
                    onMouseLeave={() => handleMouseLeave(setShowCaseStudies, caseStudiesTimer)}
                >
                    <a href="#">Case Studies</a>
                    {showCaseStudies && (
                        <div className={`case-studies-dropdown ${showCaseStudies ? 'visible' : 'hidden'}`}>
                            <Link to="/llm">LLM</Link>
                            <Link to="/cv">Computer Vision</Link>
                            <Link to="/reinforcementlearning">Reinforcement Learning</Link>
                        </div>
                    )}
                </li>

                {/*这是有用的不能删，这里是navbar顶上面的About，暂时没做好先隐藏了*/}
                {/*<li><a href="/about">About</a></li>*/}
                {/*这是有用的不能删，这里是navbar顶上面的About，暂时没做好先隐藏了*/}

                <li><Link to="/about">Why us</Link></li>
            </ul>
        </nav>
    );
}

export default Navbar;


