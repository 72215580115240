import React from 'react';
import './SolutionPage.scss';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const SolutionPage = () => {
    return (
        <div className="solution-page">
            <Navbar/>
            <section className="slide-card-header" >
                <div className='wrapper'>
                    <div className="outer-frame"></div>
                    <div className="inner-container">
                        <h1>ML Solution
                            <br></br>
                            Development</h1>

                    </div>
                </div>
            </section>

            <section className="slide-card-content">
                <div className="wrapper2">
                    <p>
                        We specialize in the development and integration of machine learning solutions, tailored to meet the unique demands of your business.
</p>
                    <p>
                        While we can leverage pre-trained models as a starting point, our strength lies in customizing these models to  fit your unique requirements, whether it's enhancing accuracy, reducing latency, or improving scalability. By harnessing our in-house expertise and technologies, alongside strategic use of cloud platforms like AWS when beneficial, we deliver a machine learning solution that is customized to advance your business objectives.
                    </p>
                    <p>
                        From deployment in the cloud to ensuring ongoing maintenance, our service guarantees scalability, security, and efficient integration with your current infrastructure, providing a comprehensive machine learning strategy that evolves with your organization.
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default SolutionPage;

