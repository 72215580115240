import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import SolutionPage from './pages/SolutionPage/SolutionPage';
import RLPage from './pages/CaseStudies/RL/RLPage';
import LLMPage from './pages/CaseStudies/LLM/LLMPage';
import CVPage from './pages/CaseStudies/CV/CVPage';
import AboutPage from "./pages/AboutPage/AboutPage";
import TermPage from "./pages/TermPage/TermPage";

import './App.css';
import ImprovementPage from "./pages/ImprovementPage/ImprovementPage";
import ConsultationPage from "./pages/ConsultantPage/ConsultationPage";
import ScrollToTop from './components/utils/ScrollToTop';


function App() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/solutions" element={<SolutionPage />} />
                <Route path="/improvement" element={<ImprovementPage />} />
                <Route path="/consultation" element={<ConsultationPage />} />
                <Route path="/reinforcementlearning" element={<RLPage />} />
                <Route path="/llm" element={<LLMPage />} />
                <Route path="/cv" element={<CVPage />} />
                <Route path='/about' element={<AboutPage />} />
                <Route path='/term' element={<TermPage />} />

                {/* ... other routes ... */}
            </Routes>
        </Router>
    );
}

export default App;
