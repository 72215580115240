import React, { useState } from 'react';
import './CVPage.scss';
import Navbar from "../../../components/Navbar/Navbar";
import Footer from '../../../components/Footer/Footer';

import steer1 from './steel_counting.jpg'
import bug1 from './bug1.jpg'
import bug2 from './bug2.jpg'
// import model1 from './model1.jpg';
// import model2 from './model2.jpg';
// import model3 from './model3.jpg';

const cases = [

    {
        id: 1,
        title: '1. Counting with Computer Vision',
        info: 'Counting the Number of Steering Bars',
        details: [
            { type:'image', src: steer1},
        ],
    },
    {
        id: 2,
        title: '2. Pests Detection',
        info: 'Pests Detection',
        details: [
            { type:'image', src: bug2},
            { type:'image', src: bug1},
        ],
    },
    // {
    //     id: 3,
    //     title: '3. Fruit Freshness Detection',
    //     info: 'Fruit Freshness Detection',
    //     details: [
    //         { type: 'title', text: 'Introduction' },
    //         { type: 'text', text: 'Fruit Detection' },
    //
    //     ],
    // },
    // Add more cases as needed.
];

const CVPage = () => {
    // This state will track which case details are visible.
    const [visibleCase, setVisibleCase] = useState(null);

    // Toggle visibility of a case based on its id.
    const toggleVisibility = (id) => {
        setVisibleCase(visibleCase === id ? null : id);
    };

    return (
        <div className="rl-page">
            <Navbar />
            <section className="slide-card-header">
                <div className='wrapper'>
                    <div className="outer-frame"></div>
                    <div className="inner-container">
                        <h1>Computer Vision</h1>
                    </div>
                </div>
            </section>

            <section className="rl-content">
                {cases.map((caseItem, index) => (
                    <div key={caseItem.id} className={`rl-wrapper2${index === 0 ? '-first-case' : ''}${index === cases.length - 1 ? '-last-case' : ''}`}>
                        <p className="case-title" onClick={() => toggleVisibility(caseItem.id)}>
                            {caseItem.title}
                        </p>
                        {visibleCase === caseItem.id && (
                            <div className={`case-details ${visibleCase === caseItem.id ? 'case-details-visible' : ''}`}>
                                <h1 className='case-inner-title'>{caseItem.info}</h1>
                                {/*{caseItem.details.map((detail, index) => (*/}
                                {/*    <p key={index}>{detail}</p>*/}
                                {/*))}*/}
                                {caseItem.details.map((detail, index) => {
                                    switch (detail.type) {
                                        case 'title':
                                            return <h2 key={index}>{detail.text}</h2>;
                                        case 'text':
                                            return detail.text.includes('\n') ? (
                                                detail.text.split('\n').map((line, index, array) => (
                                                    array.length - 1 === index ? (
                                                        <span key={index}>{line}</span> // 最后一行不添加 <br />
                                                    ) : (
                                                        <span key={index}>
        {line}
                                                            <br />
      </span>
                                                    )
                                                ))
                                            ) : (
                                                <p>{detail.text}</p> // 如果没有\n，整个文本用一个<p>包裹
                                            );
                                        case 'image':
                                            return <img className="img-container" key={index} src={detail.src} alt={`Visual representation for ${caseItem.title}`} />;
                                        default:
                                            return null;
                                    }
                                })}

                            </div>
                        )}
                    </div>
                ))}
            </section>
            <Footer />
        </div>
    );
};

export default CVPage;

