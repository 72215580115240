import React from 'react';
import './TermPage.scss';  // Make sure to update the SCSS file accordingly
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const WebsiteTermsPage = () => {
    return (
        <div className="website-term-page">
            <Navbar/>
            <section className="term-header">
                <div className='wrapper'>
                    <div className="outer-frame"></div>
                    <div className="inner-container">
                        <h1>Terms of Use</h1>
                    </div>
                </div>
            </section>

            <section className="term-content">
                <div className="term-wrapper">
                    <div className="term-section">
                        <h3>1. Acceptance of Terms</h3>
                        <ul>
                            <li>1.1 By accessing and using www.majorityai.com, you agree to adhere to and be bound by the terms and conditions outlined in these Terms of Use.</li>
                            <li>1.2 If you are accessing and using the Website on behalf of another individual or entity (e.g., a company), you confirm that you have the necessary authority to agree to these Terms on their behalf, and by doing so, that individual or entity is also bound by these Terms.</li>
                            <li>If you do not accept these terms, you are not authorized to access or use the Website, and you must cease using it immediately.</li>
                        </ul>


                    </div>
                    <div className="term-section">
                        <h3>2. Changes to Terms</h3>
                        <ul>
                            <li>www.majorityai.com reserves the right to change these Terms of Use at any time without prior notice. Your continued use of the Website following any changes constitutes your acceptance of the new terms.</li>
                            <li>These Terms were last updated on 10 August 2024. </li>
                        </ul>
                    </div>
                    <div className="term-section">
                        <h3>3. Use of the Website</h3>
                        <ul>
                            <li>You agree to use www.majorityai.com only for lawful purposes.</li>
                            <li>You are responsible for ensuring that all information you provide to us is accurate and up to date.</li>
                            <li>You agree not to use www.majorityai.com in any way that could damage, disable, overburden, or impair the Website, or interfere with any other party’s use of the Website.</li>
                        </ul>
                    </div>
                    <div className="term-section">
                        <h3>4. Intellectual Property</h3>
                        <ul>
                            <li>All content, trademarks, logos, and other intellectual property on the Website are the property of www.majorityai.com or its licensors. You may not use any content from the Website without the express written consent of www.majorityai.com.</li>
                        </ul>
                    </div>
                    <div className="term-section">
                        <h3>5. User-Generated Content</h3>
                        <ul>
                            <li>If you submit any content to the Website, including but not limited to comments, reviews, or suggestions, you grant www.majorityai.com a perpetual, non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and distribute such content in any media.</li>
                        </ul>
                    </div>
                    <div className="term-section">
                        <h3>6. Links to Third-Party Sites</h3>
                        <ul>
                            <li>The Website may contain links to third-party websites that are not owned or controlled by www.majorityai.com. www.majorityai.com has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites.</li>
                        </ul>
                    </div>
                    <div className="term-section">
                        <h3>7. Disclaimers and Limitation of Liability</h3>
                        <ul>
                            <li>7.1 The Website is provided on an “as is” and “as available” basis. www.majorityai.com makes no representations or warranties of any kind, express or implied, as to the operation of the Website or the information, content, or materials included on the Website.</li>
                            <li>7.2 To the extent permitted by law, www.majorityai.com and its licensors have no liability or responsibility to you or any other person for any loss, damage, or injury in connection with:</li>
                            <ul>
                                <li>7.2.1 The Website being unavailable (in whole or in part);</li>
                                <li>7.2.2 Any inaccuracies, errors, or omissions in any information provided on the Website;</li>
                                <li>7.2.3 Any exposure to viruses, malware, or other harmful code that may infect your computer system, software, or data as a result of your access to or use of the Website. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to the Website for any reconstruction of any lost data; and</li>
                                <li>7.2.4 Any third-party websites linked from the Website. Any links to third-party websites do not imply our endorsement, approval, or recommendation of those sites or their content, products, or services, and we assume no responsibility for them.</li>
                            </ul>
                        </ul>


                    </div>
                    <div className="term-section">
                        <h3>8. Limitation of Liability</h3>
                        <ul>
                            <li>8.1 The Website and all content provided therein are made available to you on an "as is" and "as available" basis. www.majorityai.com makes no warranties, express or implied, regarding the functionality, availability, or accuracy of the Website, nor does it guarantee that the Website will be free from errors, interruptions, or harmful elements.</li>
                            <li>8.2 Your use of the Website is at your own risk. By accessing and using the Website, you acknowledge and agree that the Website shall not be held liable for any direct, indirect, incidental, or consequential losses or damages, including but not limited to:</li>
                            <ul>
                                <li>8.2.1 Service disruptions, delays, or the Website being temporarily unavailable;</li>
                                <li>8.2.2 Errors, inaccuracies, or omissions in the content provided on the Website;</li>
                                <li>8.2.3 Any damage to your computer system or loss of data resulting from the presence of viruses, malware, or other harmful code encountered through your use of the Website;</li>
                                <li>8.2.4 Content or services provided by third-party websites linked from the Website, over which the Website has no control.</li>
                            </ul>
                            <li>8.3 In jurisdictions where limitations on liability are permitted, the Website’s liability to you, in the aggregate, for any and all claims arising from or related to your use of the Website shall not exceed the sum of NZD15.</li>
                            <li>8.4 Furthermore, the Website shall not be responsible for any loss of profits, revenue, business, or goodwill, nor for any indirect, special, incidental, or punitive damages, regardless of the form of action, whether in contract, tort (including negligence), or otherwise, arising out of or in connection with these Terms or your use of the Website.</li>
                        </ul>



                    </div>

                    <div className="term-section">
                        <h3>9. Governing Law</h3>
                        <p>These Terms of Use shall be governed by and construed in accordance with the laws of New Zealand, without regard to its conflict of law provisions.</p>
                    </div>
                    <div className="term-section">
                        <h3>10. Contact Information</h3>
                        <p>If you have any questions about these Terms of Use, please contact us: connorxiong@majorityai.com</p>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default WebsiteTermsPage;
