import React from 'react';
import './Footer.scss';
import {Link} from "react-router-dom"; // 确保你也创建了一个Footer.css来包含样式

// 关于多媒体注释部分，参考scss的footer-container的justify-content
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-contact-info">
                    <h2>Contact us</h2>
                    <p>Majority AI - Pragmatic AI for Real Results</p>
                    <p>Address: Mt Albert, Auckland,1025</p>
                    {/*<p>电话: (123) 456-7890</p>*/}
                    <p>Email: connorxiong@majorityai.com</p>

                    <p><Link to='/term' className="text-link">Website Terms of Use</Link></p>

                </div>
                {/*<div className="footer-social-media">*/}
                {/*    <h4>关注我们</h4>*/}
                {/*    <p>*/}
                {/*    <a href="你的Facebook链接" target="_blank" rel="noopener noreferrer">Facebook</a>*/}
                {/*    </p>*/}
                {/*    <p>*/}
                {/*    <a href="你的Twitter链接" target="_blank" rel="noopener noreferrer">Twitter</a>*/}
                {/*    </p>*/}
                {/*    <p>*/}
                {/*    <a href="你的Instagram链接" target="_blank" rel="noopener noreferrer">Instagram</a>*/}
                {/*    </p>*/}
                {/*    <p>*/}
                {/*    <a href="你的LinkedIn链接" target="_blank" rel="noopener noreferrer">LinkedIn</a>*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>
        </footer>
    );
};

export default Footer;
