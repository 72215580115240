import React from 'react';
import './ServiceStripe.scss';

import { Link } from 'react-router-dom';
const ServiceStripe = () => {
    return (
        <div className="our-services">
            <div className="our-services-stripe"></div>
            <div className="our-services-content">
            <h2 id={'our-service-section'}>Our Services</h2>
            <p className="our-services-content-p">Explore our comprehensive range of AI and machine learning services tailored to elevate your business. </p>
            <div className="service-cards">
                <div className="service-card">
                    <h3>Machine Learning Solution Development</h3>
                    <p>Develop and enhance machine learning models using cloud platforms like AWS and Google Cloud, ensuring customized, scalable, and secure solutions that integrate seamlessly with your operations.</p>
                    <Link to="/solutions" className="learn-more">+ Learn More</Link>
                </div>
                <div className="service-card">
                    <h3>Machine Learning Solution Improvement</h3>
                    <p>Enhance and optimize existing machine learning solutions through comprehensive reviews, online learning restructuring, model acceleration, and maintenance updates to improve performance and efficiency.</p>
                    <Link to="/improvement" className="learn-more">+ Learn More</Link>
                </div>
                <div className="service-card">
                    <h3>AI Strategy and Consultation</h3>
                    <p>Offer strategic guidance on AI implementation, from identifying impactful use cases to developing a comprehensive AI integration roadmap, ensuring businesses maximize their AI investments.</p>
                    <Link to="/consultation" className="learn-more">+ Learn More</Link>
                </div>
            </div>
            </div>
        </div>
    );
};


export default ServiceStripe;


