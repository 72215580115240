import React from 'react';
import './ConsultantBlock.scss';


const ITConsultingBlock = () => {
    const handleFindOutMoreClick = () => {
        const targetSection = document.getElementById('our-service-section');
        if (targetSection) {
            targetSection.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <div>
            <div className="it-consulting-block">
                <h2>AI Consulting & Services</h2>
                <p>
                    We offer machine learning solutions spanning from data processing to model deployment, utilizing robust algorithms and advanced engineering to address diverse business requirements.

                    {/*Our team continuously updates our industry knowledge to ensure that our practices remain at the cutting edge, empowering clients to maximize the value of their data.*/}
                </p>
                <button className="find-out-more-btn" onClick={handleFindOutMoreClick}>Find out more</button>
            </div>
        </div>
    );
};

export default ITConsultingBlock;