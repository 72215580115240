import React from 'react';
import office_img from '../../office.jpg';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import ConsultantBlock from '../../components/ConsultantBlock/ConsultantBlock';
import ServiceStripe from '../../components/ServiceStripe/ServiceStripe';

import './HomePage.scss';

function HomePage() {
  return (
    <div className="App">
      <Navbar />
        <header className="App-header">
            <div className="background-image-container" style={{backgroundColor: 'white'}}>
                <img src={office_img} alt="office"
                     fetchPriority="high" className="office"/>

                <div className="content-overlay">
                    <ConsultantBlock />
                </div>
            </div>

            <div className="section-spacing" style={{backgroundColor: 'white'}}><ServiceStripe /></div>
            {/*<img src={logo} className="App-logo" alt="logo"/>*/}
            {/*<p>*/}
            {/*    Edit <code>src/App.js</code> and save to reload.*/}
            {/*</p>*/}
            {/*<a*/}
            {/*    className="App-link"*/}
            {/*    href="https://reactjs.org"*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*>*/}
            {/*    Learn React*/}
            {/*</a>*/}

        </header>
        <Footer />
    </div>
  );
}

export default HomePage;