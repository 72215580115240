import React, { useState } from 'react';
import './RLPage.scss';
import Navbar from "../../../components/Navbar/Navbar";
import Footer from '../../../components/Footer/Footer';

import basement from './basement.jpg';
import model1 from './model1.jpg';
import model2 from './model2.jpg';
import model3 from './model3.jpg';

const cases = [
    {
        id: 1,
        title: '1. Reinforcement Learning in Competitive Survival Strategy Games',
        info: 'Enhancing AI Performance in a Competitive Resource Management Game',
        details: [
            { type:'image', src: basement},
            { type: 'title', text: 'Introduction' },
            { type: 'text', text: 'This case study explores the application of machine learning models to optimize strategies in an AI-driven game where players control teams to collect resources, build cities, and generate units. The challenge lies in efficiently managing resources to not only construct cities but also utilize these cities as production centers for creating more units. The ultimate goal is to maximize city within a specified timeframe.' },
            { type: 'title', text: 'Problem Statement' },
            { type: 'text', text: 'The game requires players to manage units strategically, collecting resources like wood and stone to develop cities. These units and cities consume resources over time, adding complexity to resource management. The primary goal is to maximize city construction within a limited timeframe, necessitating efficient strategy and resource allocation.' },
            { type: 'title', text: 'Approach and Methodology' },
            { type: 'text', text: 'Our solution employs imitation learning, a technique that models our AI agents\' actions on the strategies of high-scoring players. This approach involved several steps:'},
            { type: 'text', text: ' - Input: The model receives data on agent movements and the game map. \n' +
                    ' - Model Architecture: 2DCNN+MLP\n' +
                    ' - Output: The model predicts the likelihood of executing specific movements.\''},
            { type: 'title', text: 'Development Stages and Improvements' },
            { type: 'text', text: 'Our development process explored various techniques to enhance model performance:'},
            { type: 'text', text: '1. Baseline Imitation Learning: Initiated with a basic imitation learning model.\n' +
                    '2. Learning Rate Adjustment: Lowering the learning rate improved accuracy. (+)\n' +
                    '3. Learning Scheduler: Implementing a learning rate scheduler further boosted performance. (+)\n' +
                    '4. Data Enrichment: Adding more high score training data. (+)\n' +
                    '5. Data Augmentation: Enhanced the diversity of the training dataset. (/)\n' +
                    '6. Multi-Model Voting: Tried combining predictions from multiple models, which did not yield positive results. (-)\n' +
                    '7. Input Data Modification: Adjusting the input data format significantly increased accuracy.(+)\n' +
                    '8. Data Cleaning: Refining the dataset by removing outliers and irrelevant data. (+)\n' +
                    '9. Loss Function Optimization: Customizing the loss function to better suit our specific game dynamics. (+)\n' +
                    '10. Batch Size and Epoch Adjustment: Fine-tuning these parameters led to the highest performance increase. (+)' },
            { type: 'title', text: 'Results' },
            { type: 'text', text: 'The iterative improvement process led to a significant increase in model performance, with the final adjustments improving the game strategy AI\'s effectiveness remarkably:'},
            { type: 'text', text: '- Initial Imitation Learning Performance: 60%\n' +
                    '- Final Model Performance: 90%\n' },
            { type: 'title', text: 'Cost Analysis' },
            { type: 'text', text: 'Utilizing Google Vertex AI\'s Kaggle notebook environment, the training process incurred costs of approximately $6 USD/hour. Each training session lasted between 5-6 hours, making the experimentation phase both resource-intensive and costly.' },
            { type: 'title', text: 'Conclusion' },
            { type: 'text', text: 'Our success came from using a simple mix of 2D CNN and MLP and the well-designed input data and the quality training data. This case study shows that preparing data well is just as important as having a complex model. It points out how important it is to focus on the quality of data to make the most of AI solutions.' },
            { type: 'title', text: 'Appendix: Model Code' },
            { type:'image', src: model2},
            { type:'image', src: model1},
            { type:'image', src: model3}
        ],
    },

    {
        id: 2,
        title: '2. Tetris',
        info: 'RL Agents Playing Tetris',
        details: [
            { type: 'text', text: 'We will soon update a case involving a reinforcement learning agent that can play Tetris.' },
        ],
    },
    // Add more cases as needed.
];

const RLPage = () => {
    // This state will track which case details are visible.
    const [visibleCase, setVisibleCase] = useState(null);

    // Toggle visibility of a case based on its id.
    const toggleVisibility = (id) => {
        setVisibleCase(visibleCase === id ? null : id);
    };

    return (
        <div className="rl-page">
            <Navbar />
            <section className="slide-card-header">
                <div className='wrapper'>
                    <div className="outer-frame"></div>
                    <div className="inner-container">
                        <h1>Reinforcement Learning</h1>
                    </div>
                </div>
            </section>

            <section className="rl-content">
                {cases.map((caseItem, index) => (
                    <div key={caseItem.id} className={`rl-wrapper2${index === 0 ? '-first-case' : ''}${index === cases.length - 1 ? '-last-case' : ''}`}>
                        <p className="case-title" onClick={() => toggleVisibility(caseItem.id)}>
                            {caseItem.title}
                        </p>
                        {visibleCase === caseItem.id && (
                            <div className={`case-details ${visibleCase === caseItem.id ? 'case-details-visible' : ''}`}>
                                <h1 className='case-inner-title'>{caseItem.info}</h1>
                                {/*{caseItem.details.map((detail, index) => (*/}
                                {/*    <p key={index}>{detail}</p>*/}
                                {/*))}*/}
                                {caseItem.details.map((detail, index) => {
                                    switch (detail.type) {
                                        case 'title':
                                            return <h2 key={index}>{detail.text}</h2>;
                                        case 'text':
                                            return detail.text.includes('\n') ? (
                                                detail.text.split('\n').map((line, index, array) => (
                                                    array.length - 1 === index ? (
                                                        <span key={index}>{line}</span>
                                                    ) : (
                                                        <span key={index}>
        {line}
                                                            <br />
      </span>
                                                    )
                                                ))
                                            ) : (
                                                <p>{detail.text}</p> // 如果没有\n，整个文本用一个<p>包裹
                                            );
                                        case 'image':
                                            return <img className="img-container" key={index} src={detail.src} alt={`Visual representation for ${caseItem.title}`} />;
                                        default:
                                            return null;
                                    }
                                })}

                            </div>
                        )}
                    </div>
                ))}
            </section>

            <Footer />

        </div>

    );
};

export default RLPage;

