import React from 'react';
import './ConsultationPage.scss';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const ConsultationPage = () => {
    return (
        <div className="slide-card-page">
            <Navbar/>
            <section className="slide-card-header" >
                <div className='wrapper'>
                    <div className="outer-frame"></div>
                    <div className="inner-container">
                        <h1>Consultation</h1>
                    </div>
                </div>
            </section>

            <section className="slide-card-content">
                <div className="wrapper2">
                    <p>
                        Our consultancy specializes in developing tailored AI strategies that align with your business goals, driving innovation and securing a competitive edge. We guide you from concept to full-scale AI deployment, leveraging deep industry knowledge and technological expertise to deliver practical solutions and tangible results.
</p>
                    <p>
                        Partner with us to seamlessly integrate AI into your business processes, optimizing operations and unlocking new opportunities. Let’s create a robust AI strategy together, propelling your business into the future.
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default ConsultationPage;

