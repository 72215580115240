import React, { useState } from 'react';
import './LLMPage.scss';
import Navbar from "../../../components/Navbar/Navbar";
import Footer from '../../../components/Footer/Footer';
//
import bot_structure from './langchain_chatbot_structure.jpg';
import scheduling_agent from './scheduling LLM agent.jpg'
// import model2 from './model2.jpg';
// import model3 from './model3.jpg';

const cases = [
    {
        id: 1,
        title: '1. Integrating LLM-Backed Agents Across Applications',
        info: 'Your Gateway to Smart Communication and Information Retrieval',
        details: [
            { type:'image', src: bot_structure},
            { type: 'title', text: 'Introduction' },
            { type: 'text', text: 'This case study explores a LLM-backed chatbot integrated with different applications, ' +
                    'such as Telegram. The chatbot includes agents capable of accessing up-to-date internet information, ' +
                    'supporting a local customized knowledge base, and assisting with daily email tasks. The system boasts high reliability, ' +
                    'featuring elastic load balancing and an active-passive failover mechanism to ensure continuous operation.' },
            { type: 'title', text: 'Problem Statement' },
            { type: 'text', text: 'LLMs now offer a high-quality communication experience. ' +
                    'However, users typically need to visit official websites, such as those for ChatGPT and Anthropic, to interact with these chatbots. ' +
                    'To support more customized user scenarios and domain-specific knowledge, a system integrated with LLMs presents a feasible solution.' },
            { type: 'title', text: 'Approach and Methodology' },
            { type: 'text', text: 'The project was initiated with a selection of technical tools, prioritizing the tools\' usability and the project\'s complexity to determine the most suitable technology stack. ' +
                    'Development began by establishing key functionalities that ensure a smooth data flow—from user inputs through the LLM APIs to the chatbot\'s output—thus enabling seamless user interactions. ' +
                    'Additional features were added methodically, continually enhancing and refining the development to meet evolving needs.' },
            { type: 'title', text: 'Technical Architecture' },
            { type: 'text', text: '- Users can interact via different platforms, ' +
                    'including Telegram, Discord, Slack, and customer\'s app, all \n connected through APIs.\n' +
                    '- FastAPI supports the API communication in the app.\n'+
                    '- LangChain manages complex query data flows and orchestrates the retrieval-augmented generation process.\n' +
                    '- The usage of Docker allows for consistent environments and easy management across different deployment \n scenarios. \n' +
                    '- The entire infrastructure is deployed on AWS to leverage robust cloud computing capabilities, ' +
                    'including \n managed database services and auto-scaling. ' +
                    'Docker images are stored and managed in AWS ECR and \n deployed using Amazon ECS for high availability and resilience. ' +
                    'To ensure durability and optimum performance, \n '},
            { type: 'title', text: 'Key Features' },
            { type: 'text', text: '1. Customized Information Retrieval: Utilizes Retrieval-Augmented Generation (RAG) based on customers\' own \n' +
                    ' data to provide answers to business-related questions. \n' +
                    '2. Internet Lookup: Enables the chatbot to query the internet to fetch required information. \n' +
                    '3. Email Assistant: Integrates with email systems to help customers draft and send emails conveniently. \n' +
                    '4. Memory: Enables the chatbot to remember the user\'s short-term conversation history, allowing for more \n contextually relevant and personalized interactions. '},
            { type: 'title', text: 'Load Balancing' },
            { type: 'text', text: 'Elastic Load Balancing (ELB) is implemented to distribute incoming application traffic across multiple instances, \n ' +
                    'effectively managing traffic spikes without any downtime.' },
            { type: 'title', text: 'Failover Mechanisms' },
            { type: 'text', text: '- Redis Sentinel: Manages the Redis deployments, offering high availability and failover support. ' +
                    'It monitors the \n system and automatically switches to a standby server in case the primary server fails, minimizing service\n interruptions. \n' +
                    '- Active-Passive Failover: In the backend, databases are set up in an active-passive configuration. \n \n' },


            // { type:'image', src: model1},
            // { type:'image', src: model3}
        ],
    },
    {
        id: 2,
        title: '2. Quantizing llama3-8b to W4A16',
        info: 'Quantizing llama3-8b to W4A16',
        details: [
            { type: 'title', text: 'Problem Statement' },
            { type: 'text', text: 'The llama3-8b model requires significant computational resources and storage, ' +
                    'presenting challenges in environments with strict constraints on memory and processing power. ' +
                    'The primary challenge is to reduce these demands without compromising the model\'s effectiveness.'},
            { type: 'title', text: 'llama3-8b Overview' },
            { type: 'text', text: 'Llama (Large Language Model Meta AI) is an AI developed by Meta (formerly Facebook), ' +
                    'which includes the llama3-8b model featuring an 80B (80 billion) parameter size, ' +
                    'built on the Transformer architecture.'},
            { type: 'title', text: 'Approach and Methodology' },
            { type: 'text', text: '' +
                    '1. Weights (W4): Quantization of weights to 4-bit precision to minimize storage demands and optimize processing speed.'},
            { type: 'text', text: '' +
                    '2. Activations (A16): Quantization of activations to 16-bit to maintain a balance between efficiency and model performance. '
            },
            { type: 'title', text: 'Accuracy - FP16' },
            { type: 'text', text: '' +
                    'The model scored <strong>25.61</strong> on the human-eval dataset, indicating a robust performance with high precision settings.'
            },
            { type: 'title', text: 'Accuracy - W4A16' },
            { type: 'text', text: '' +
                    'After quantization, the model achieved a score of <strong>21.95</strong>, demonstrating that it retains a high level of effectiveness even with reduced precision.'},

            { type: 'text', text: ''+
                    'The quantized model shows a significant reduction in memory usage and improved processing speed, making it highly suitable for deployment in constrained environments.' +
                    ' Despite the lower bit-depth in quantization, the model maintains a commendable level of accuracy, validating the efficacy of the quantization approach.'
            },
            { type: 'title', text: 'Outcomes' },
            { type: 'text', text: '' +
                    '1. Efficiency Gains: The quantized model shows a significant reduction in memory usage and improved processing speed, making it highly suitable for deployment in constrained environments.'},
            { type: 'text', text: '' +
                    '2. Preservation of Accuracy: Despite the lower bit-depth in quantization, the model maintains a commendable level of accuracy, validating the efficacy of the quantization approach.',
            },
            { type: 'title', text: 'Resources' },
            { type: 'text', text: '' +
                    '1. https://huggingface.co/meta-llama/Meta-Llama-3-8B'},
            { type: 'text', text: '' +
                    '2. https://github.com/open-compass/opencompass',
            },
        ],
    },
    {
        id: 3,
        title: '3. Appointment Scheduling LLM Agent',
        info: 'Appointment Scheduling LLM Agent',
        details: [
            { type:'image', src: scheduling_agent},
            { type: 'title', text: 'System Overview' },
            { type: 'text', text: 'This system aims to streamline the process of client management and appointment scheduling, ' +
                    'reducing the manual effort required by business owners.'},
        ],
    },
    // Add more cases as needed.
];



const LLMPage = () => {

    const parseText = (text) => {
        const parts = []; // 存储分解后的文本片段
        let lastIndex = 0; // 上一个匹配的结束索引
        const regex = /<strong>(.*?)<\/strong>/g; // 正则表达式匹配<strong>标签

        // 处理<strong>标签内的文本
        text.replace(regex, (match, p1, index) => {
            // 推入<strong>前的普通文本
            parts.push(text.substring(lastIndex, index));
            // 推入<strong>内的加粗文本
            parts.push(<strong key={index}>{p1}</strong>);
            lastIndex = index + match.length; // 更新上一个索引
        });

        // 最后一段普通文本
        parts.push(text.substring(lastIndex));
        return parts;
    };



    // This state will track which case details are visible.
    const [visibleCase, setVisibleCase] = useState(null);

    // Toggle visibility of a case based on its id.
    const toggleVisibility = (id) => {
        setVisibleCase(visibleCase === id ? null : id);
    };

    return (
        <div className="rl-page">
            <Navbar />
            <section className="slide-card-header">
                <div className='wrapper'>
                    <div className="outer-frame"></div>
                    <div className="inner-container">
                        <h1>LLMs</h1>
                    </div>
                </div>
            </section>

            <section className="rl-content">
                {cases.map((caseItem, index) => (
                    <div key={caseItem.id} className={`rl-wrapper2${index === 0 ? '-first-case' : ''}${index === cases.length - 1 ? '-last-case' : ''}`}>
                        <p className="case-title" onClick={() => toggleVisibility(caseItem.id)}>
                            {caseItem.title}
                        </p>
                        {visibleCase === caseItem.id && (
                            <div className={`case-details ${visibleCase === caseItem.id ? 'case-details-visible' : ''}`}>
                                <h1 className='case-inner-title'>{caseItem.info}</h1>
                                {/*{caseItem.details.map((detail, index) => (*/}
                                {/*    <p key={index}>{detail}</p>*/}
                                {/*))}*/}
                                {caseItem.details.map((detail, index) => {
                                    switch (detail.type) {
                                        case 'title':
                                            return <h2 key={index}>{detail.text}</h2>;
                                        case 'text':
                                            return detail.text.includes('\n') ? (
                                                detail.text.split('\n').map((line, index, array) => {
                                                    const content = parseText(line); // 对每行应用parseText来处理加粗标签
                                                    return (
                                                        array.length - 1 === index ? (
                                                            <span key={index}>{content}</span> // 最后一行不添加<br />
                                                        ) : (
                                                            <span key={index}>
            {content}
                                                                <br />
          </span>
                                                        )
                                                    );
                                                })
                                            ) : (
                                                <p>{parseText(detail.text)}</p> // 如果没有\n，直接处理整个文本
                                            );
                                        case 'image':
                                            return <img className="img-container" key={index} src={detail.src} alt={`Visual representation for ${caseItem.title}`} />;
                                        default:
                                            return null;
                                    }
                                })}

                            </div>
                        )}
                    </div>
                ))}
            </section>
            <Footer />
        </div>
    );
};

export default LLMPage;

